import { Pipe, PipeTransform } from '@angular/core';
import {environment} from '../../../environments/environment';

@Pipe({
  name: 'apiUrlPipe'
})
export class ApiUrlPipe implements PipeTransform {

  private readonly domain = environment.apiDomain;

  transform(value: string): string {
    const isWithDomain = value.startsWith(this.domain);

    return isWithDomain ? value : this.domain + value;
  }

}
