import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BannerService {

  public readonly STATE_CLOSED = 1;
  public readonly STATE_BANNER_SMALL = 2;
  public readonly STATE_BANNER_LARGE = 3;
  public readonly STATE_BANNER_SMALL_INITIAL = 4;

  private readonly _state = new BehaviorSubject(this.STATE_BANNER_LARGE);

  readonly state$ = this._state.asObservable();

  constructor() {
  }

  set state(val: number) {
    this._state.next(val);
  }

  get state(): number {
    return this._state.getValue();
  }

  setState(val: number): void {
    this.state = val;
  }
}
