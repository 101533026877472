<ng-container *ngIf="videos$ | async as videos">
    <ng-container *ngFor="let video of videos; let i = index">
        <div class="header-banner-wrapper" *ngIf="i === (offset$ | async)" [@inOutAnimation]>
            <app-video-container
                    [video_360]="video.video_360"
                    [video_540]="video.video_540"
                    [video_720]="video.video_720"
                    [video_1080]="video.video_1080"
                    (videoEnded)="playNextVideo($event, videos)"
            ></app-video-container>
        </div>
    </ng-container>
</ng-container>
