import {BehaviorSubject, Observable} from 'rxjs';
import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  private menuShown = new BehaviorSubject<boolean>(false);

  constructor() {
  }

  setMenuState(isMenuShown: boolean): void {
    this.menuShown.next(isMenuShown);
  }

  isMenuActive(): Observable<boolean> {
    return this.menuShown.asObservable();
  }

  hideMenu(): void {
    this.setMenuState(false);
  }

  showMenu(): void {
    this.setMenuState(true);
  }

  toggleMenu(): void {
    this.setMenuState(!this.menuShown.value);
  }
}
