<app-header class="position-fixed" [futureAgencyPath]="['/yourfutureagency']"></app-header>

<section [ngClass]="{'overflow-hidden': (menuState$ | async) === true}">
  <ng-container *ngIf="bannerState$ | async as bannerState">
    <div class="banner-overlay" (click)="bannerOverlayClicked()"></div>
    <article class="page-body"
             (mouseover)="showBanner()"
             [class.banner-closed]="bannerState === STATE_CLOSED"
             [class.banner-small]="bannerState === STATE_BANNER_SMALL"
             [class.banner-small-initial]="bannerState === STATE_BANNER_SMALL_INITIAL"
             [class.banner-large]="bannerState === STATE_BANNER_LARGE">
      <app-blur *ngIf="cases$ | async as cases" class="d-block">

        <!-- Mobile -->
        <div class="d-md-none">
          <div class="swiper-navs-container">
            <div class="swiper-navigation-prev"></div>
            <h3>swipe for next case</h3>
            <div class="swiper-navigation-next"></div>
          </div>
          <swiper [loop]="true" [loopedSlides]="1" [slidesPerView]="1" [navigation]="swiperNavigation"
                  [breakpoints]="swiperBreakpoints">
            <ng-template swiperSlide *ngFor="let c of cases; let idx = index">
              <div class="carousel-title" (mousedown)="toggleBanner()" style="cursor: pointer">
                <h1 class="text-center banner-carousel-title">{{c.project_type.project_type_slogan}}</h1>
              </div>
              <div class="slide-item keep-ratio">
                <div class="item-container">
                  <app-video-banner-item [banner]="c" [index]="idx + 1"></app-video-banner-item>
                </div>
              </div>
            </ng-template>
          </swiper>

          <div class="mobile-swiper-nav position-relative d-flex align-content-center justify-content-between">
            <div class="swiper-navigation-prev"></div>
            <h3>swipe for next case</h3>
            <div class="swiper-navigation-next"></div>
          </div>
        </div>


        <!-- Desktop -->
        <div class="d-none d-md-block" *ngIf="projectTypes$ | async as projectTypes">
          <div class="swiper-navs-container d-none" [class.d-block]="bannerState !== STATE_BANNER_SMALL_INITIAL">
            <div class="swiper-navigation-prev"></div>
            <div class="swiper-navigation-next"></div>
          </div>
          <swiper [loop]="true" [loopedSlides]="1" [slidesPerView]="1" [navigation]="swiperNavigation"
                  [breakpoints]="swiperBreakpoints">
            <ng-template swiperSlide *ngFor="let p of projectTypes; let idx = index">
              <div class="carousel-title" (click)="toggleBanner()" style="cursor: pointer">
                <h1 class="text-center banner-carousel-title">{{p.project_type_slogan}}</h1>
              </div>

              <div class="row">
                <div class="col" *ngFor="let c of p.banner_projects; let caseIdx = index">
                  <div class="slide-item keep-ratio">
                    <div class="item-container">
                      <app-video-banner-item [banner]="c" [index]="idx * 3 + (caseIdx + 1)"></app-video-banner-item>
                    </div>
                  </div>
                </div>
              </div>
            </ng-template>
          </swiper>
        </div>
      </app-blur>
    </article>
  </ng-container>
</section>
