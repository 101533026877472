import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {YourFutureAgencyComponent} from './your-future-agency.component';
import {HeaderModule} from '../../shared/header/header.module';
import {BlurModule} from '../../shared/blur/blur.module';
import {RouterModule} from '@angular/router';


@NgModule({
  declarations: [YourFutureAgencyComponent],
  imports: [
    CommonModule,
    BlurModule,
    HeaderModule,
    RouterModule,
  ]
})
export class YourFutureAgencyModule {
}
