import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { NgxHowlerService } from 'ngx-howler';
import {BehaviorSubject, Observable} from 'rxjs';
import {shareReplay, tap} from 'rxjs/operators';
import { isPlatformBrowser } from '@angular/common';
import { Howl } from 'howler';

@Injectable({
    providedIn: 'root'
})
export class BackgroundAudioService {
    private _playing: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private howlPlayer: Howl;
    constructor(private howl: NgxHowlerService, @Inject(PLATFORM_ID) protected platformId,
    ) {

    }

    init(): Observable<any> {
        // if (isPlatformBrowser(this.platformId)) {
        //     this.howl.register('background', {
        //         src: ['/assets/ovdallll.mp3'],
        //         loop: true,
        //         html5: true
        //     }).pipe(
        //         shareReplay(1),
        //         tap(() => {
        //           this.startPlaying();
        //           this._playing.next(true);
        //         })
        //     ).subscribe();
        // }

      if (isPlatformBrowser(this.platformId)) {
         return this.howl.register('background', {
          src: ['/assets/ovdallll.mp3'],
          loop: true,
          html5: true
        }).pipe(
          shareReplay(1),
          tap(() => {
            this.startPlaying();
            this._playing.next(true);
          })
        );
      }
    }

    startPlaying(): void {
        if (!this._playing.value) {
            this.howlPlayer = this.howl.get('background');
            this.howlPlayer.play();
            this._playing.next(true);
        }
    }

    playing(): Observable<boolean> {
        return this._playing.asObservable();
    }

    isPlaying(): boolean {
        return this._playing.value;
    }

    stopPlaying(): void {
        if (this.isPlaying()) {
            this.howlPlayer.stop();
            this._playing.next(false);
        }
    }
}
