import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InterviewComponent } from './interview.component';
import { VideoSliderModule } from "../../shared/video-slider/video-slider.module";
import { BlurModule } from "../../shared/blur/blur.module";
import { HeaderModule } from "../../shared/header/header.module";
import { VideoPlayerModule } from "../../shared/video-player/video-player.module";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { BrowserModule } from "@angular/platform-browser";


@NgModule({
    declarations: [InterviewComponent],
    imports: [
        CommonModule,
        VideoSliderModule,
        BlurModule,
        HeaderModule,
        VideoPlayerModule,
        BrowserModule,
        BrowserAnimationsModule,
    ]
})
export class InterviewModule {
}
