import {Component, Input } from '@angular/core';
import {CaseModel} from '../../models/case.model';

@Component({
  selector: 'app-video-banner-item',
  templateUrl: './video-banner-item.component.html',
  styleUrls: ['./video-banner-item.component.scss'],
})
export class VideoBannerItemComponent {
  @Input() banner: CaseModel;
  @Input() index: number;
}
