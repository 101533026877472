import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';
import { timer } from 'rxjs';
import { mapTo } from 'rxjs/operators';
import Player from '@vimeo/player';
import { MediaModel } from '../../models/media.model';

@Component({
    selector: 'app-video-container',
    templateUrl: './video-container.component.html',
    styleUrls: ['./video-container.component.scss'],
})
export class VideoContainerComponent implements OnInit, OnDestroy, AfterViewInit {

    @ViewChild('videoElement') videoElement: ElementRef;

    @Input() muted = true;

    @Input() video_360: MediaModel;
    @Input() video_540: MediaModel;
    @Input() video_720: MediaModel;
    @Input() video_1080: MediaModel;
    @Input() captions_file: MediaModel;
    @Input() poster: MediaModel;

    @Input() loop = false;
    @Input() slogan: string;
    @Input() showPlayButton = false;

    @Output() videoEnded = new EventEmitter();
    @Output() videoStarted = new EventEmitter();

    player: Player;
    volumeLevel = 0;
    allowEmit = true;

    currentVideo: MediaModel;

    private responsive: { [key: number]: MediaModel };

    // @Input() vimeoVideoId: number;

    constructor(
            private hostElement: ElementRef,
            private changeDetectorRef: ChangeDetectorRef,
    ) {
    }

    @HostListener('window:resize') onResize(): void {
        this.syncVideoSource();
        this.initPlayer();
    }

    ngAfterViewInit(): void {
        this.syncVideoSource();
        this.changeDetectorRef.detectChanges();
        this.initPlayer();
    }

    ngOnInit(): void {
        this.responsive = {
            0: this.video_360,
            360: this.video_540,
            540: this.video_720,
            720: this.video_1080,
        };
    }

    ngOnDestroy(): void {
        if (this.videoElement) {
            this.videoElement.nativeElement.pause();
        }
    }

    playMutedVideo(): void {
        this.showPlayButton = false;
        const media = this.videoElement.nativeElement;
        media.muted = true;
        media.play();
        this.videoStarted.emit(true);
    }

    initPlayer(): void {
        if (this.videoElement && !this.showPlayButton) {
            const media = this.videoElement.nativeElement;
            media.muted = true;
            media.loop = this.loop;
            media.play();
        }
    }

    // stop(): void {
    //     this.player.unload();
    // }

    ended(): void {
        this.videoEnded.emit(true);
    }

    timeUpdate(event): void {
        if (event.target.currentTime > event.target.duration - 2) {
            if (this.allowEmit) {
                this.allowEmit = false;
                // event.target.pause();
                // event.target.currentTime = 0;
                this.videoEnded.emit(true);
            }
        }
    }

  toggleSound(): void {
    this.volumeLevel = this.volumeLevel ? 0 : 1;
    this.player.setVolume(this.volumeLevel).then();
  }

  canDeactivate(): Promise<boolean> {
    return timer(1000).pipe(mapTo(true)).toPromise();
  }

    /**
     * Update source based on media query size
     *
     * @private
     *
     * @return void
     */
    private syncVideoSource(): void {
        const properVideoSize = Object.keys(this.responsive)
        .reverse()
        .find(minWidth => this.hostElement.nativeElement.offsetWidth >= parseInt(minWidth, 10));
        this.currentVideo = this.responsive[properVideoSize];
    }

}
