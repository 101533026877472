<nav class="navigation-menu">
  <article class="content">
    <h2 class="promo-header">I’ve seen the future and it will be</h2>
  </article>
  <ul class="menu-list">
    <li class="menu-item">
      <a [routerLink]="['/', 'yourfutureagency']" class="menu-item-link" (click)="hideMenu()">
        <span>Your future agency</span>
      </a>
      <ul class="menu-list--sub">
        <li class="sub-item">
          <a routerLink="/about" class="sub-item--link" (click)="hideMenu()">
            <span>About</span>
          </a>
        </li>
        <li class="sub-item">
          <a routerLink="/interview" class="sub-item--link" (click)="hideMenu()">
            <span>Interview with the founder</span>
          </a>
        </li>
        <li class="sub-item">
          <a routerLink="/contact" class="sub-item--link" (click)="hideMenu()">
            Contact
          </a>
        </li>
      </ul>
    </li>
    <li class="menu-item" *ngFor="let projectType of projectTypes$ | async">
      <span class="menu-item-link">{{projectType.project_type_slogan}}</span>
      <ul class="menu-list--sub">
        <li class="sub-item" *ngFor="let item of projectType.banner_projects">
          <a [routerLink]="['/cases', item.slug]" class="sub-item--link" (click)="hideMenu()">
            <span>{{item.title}}</span>
          </a>
        </li>
      </ul>
    </li>

    <li class="menu-item">
      <a [routerLink]="['/', 'terms']" (click)="hideMenu()"><span class="menu-item-link">Terms</span></a>
    </li>

    <li class="menu-item">
      <a [routerLink]="['/', 'use-of-cookie']" (click)="hideMenu()"><span class="menu-item-link">Use of cookie</span></a>
    </li>

    <li class="menu-item">
      <a [routerLink]="['/', 'information-about-data-sub-processors']" (click)="hideMenu()"><span class="menu-item-link">Information about data sub-processors</span></a>
    </li>
  </ul>
</nav>
