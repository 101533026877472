import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {BannerBackgroundVideoModel, BannerBackgroundVideoResponse} from '../models/banner-background-video.model';
import { strapiNormalize } from '../strategy/strapi-response-normalizer';
import {map, switchMap, tap} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class BackgroundVideoService {
  private videos: BehaviorSubject<BannerBackgroundVideoModel[]> = new BehaviorSubject<BannerBackgroundVideoModel[]>([]);

  private videos$ = this.videos.asObservable();
  private current: BehaviorSubject<number> = new BehaviorSubject<number>(0);

  constructor(private http: HttpClient) {

  }

  // loadVideos() {
  //   this.fetchAll().subscribe((videosResponse: BannerBackgroundVideoResponse) => {
  //     let videos: BannerBackgroundVideoModel[] = strapiNormalize(videosResponse.data);
  //     this._videos.next(videos);
  //   })
  // }

  getAll(): Observable<BannerBackgroundVideoModel[]> {
    return this.videos$.pipe(
      switchMap((videos) => {
        if (videos && videos.length) { return of(videos); }

        return this.fetchAll();
      })
    );
  }

  // getAll(): Observable<BannerBackgroundVideoModel[]> {
  //   return this.videos.asObservable();
  // }

  get(id): Observable<BannerBackgroundVideoModel> {
    return this.http.get<BannerBackgroundVideoModel>('/banner-background-videos/' + id);
  }

  slideToIndex(index): void {
    this.current.next(index);
  }

  getCurrentIndex(): number {
    return this.current.value;
  }

  getIndexChange(): Observable<number> {
    return this.current.asObservable();
  }

  private fetchAll(): Observable<BannerBackgroundVideoModel[]> {
    return this.http.get<BannerBackgroundVideoResponse>('/banner-background-videos?populate=*').pipe(
      map((response) => strapiNormalize(response.data)),
      tap((data) => this.videos.next(data))
    );
  }
}
