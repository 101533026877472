<a class="slide-banner-item d-flex flex-column p-4 align-items-start justify-content-start"
   [routerLink]="['/cases', banner.slug]">

  <div class="slider--item-video" [ngStyle]="{'background-image': 'url(' + (banner.preview_gif.url | apiUrlPipe) + ')'}">
  </div>
  <div class="slider--item-descr">
    <h2 class="slider-banner-item--title">Case {{index}}</h2>
    <p class="slider-banner-item--descr">{{banner.title}}</p>
  </div>
</a>
