import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BlurComponent } from './blur.component';



@NgModule({
    declarations: [BlurComponent],
    exports: [
        BlurComponent
    ],
    imports: [
        CommonModule
    ]
})
export class BlurModule { }
