<header class="header">
  <a [routerLink]="['/']" class="logo" (click)="logoClick()">
    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="157.684" height="28"
      viewBox="0 0 157.684 28">
      <defs>
        <clipPath id="clip-path">
          <path id="Clip_2" data-name="Clip 2" d="M0,0H28.737V28H0Z" transform="translate(0 0)" fill="#fff" />
        </clipPath>
        <clipPath id="clip-path-2">
          <path id="Clip_7" data-name="Clip 7" d="M0,0H24.316V28H0Z" transform="translate(0.369)" fill="#fff" />
        </clipPath>
        <clipPath id="clip-path-3">
          <path id="Clip_12" data-name="Clip 12" d="M0,0H21.368V28H0Z" transform="translate(0)" fill="#fff" />
        </clipPath>
      </defs>
      <g id="LOGO" transform="translate(0)">
        <g id="Group_3" data-name="Group 3">
          <path id="Clip_2-2" data-name="Clip 2" d="M0,0H28.737V28H0Z" transform="translate(0 0)"
            fill="rgba(0,0,0,0)" />
          <g id="Group_3-2" data-name="Group 3" clip-path="url(#clip-path)">
            <path id="Fill_1" data-name="Fill 1"
              d="M16.251,28H12.485c-4.021,0-7.14-1.159-9.272-3.443S0,18.741,0,14.062C0,9.355,1.081,5.8,3.213,3.484S8.464,0,12.485,0h3.767c4.021,0,7.14,1.172,9.272,3.484s3.214,5.872,3.214,10.578c0,4.679-1.081,8.21-3.214,10.495S20.272,28,16.251,28ZM12.485,4.9c-2.662,0-4.624.726-5.833,2.156S4.83,10.841,4.83,14.062c0,3.194.606,5.525,1.8,6.928S9.8,23.1,12.485,23.1h3.767c2.635,0,4.584-.719,5.792-2.136s1.821-3.756,1.821-6.949-.606-5.538-1.8-6.969S18.912,4.9,16.251,4.9Z"
              transform="translate(0 0)" fill="#fff" />
          </g>
        </g>
        <path id="Fill_4" data-name="Fill 4"
          d="M5.386,0l8.493,22.131a1.361,1.361,0,0,0,1.367.938,1.409,1.409,0,0,0,1.368-.938L25.521,0h5.427l-9.86,24.454a5.211,5.211,0,0,1-2.216,2.751A7.4,7.4,0,0,1,15.246,28a7.964,7.964,0,0,1-3.583-.753,4.9,4.9,0,0,1-2.3-2.793L0,0Z"
          transform="translate(33.158)" fill="#fff" />
        <g id="Group_8" data-name="Group 8" transform="translate(70.737)">
          <path id="Clip_7-2" data-name="Clip 7" d="M0,0H24.316V28H0Z" transform="translate(0.369)" fill="none" />
          <g id="Group_8-2" data-name="Group 8" clip-path="url(#clip-path-2)">
            <path id="Fill_6" data-name="Fill 6"
              d="M11.954,28H2.277A2.02,2.02,0,0,1,0,25.718V2.282A2.02,2.02,0,0,1,2.277,0h9.677c4.02,0,7.119,1.159,9.211,3.443s3.152,5.843,3.152,10.577c0,4.706-1.06,8.251-3.152,10.537S15.976,28,11.954,28ZM5.326,4.9c-.351,0-.528.167-.528.5V22.608c0,.33.178.5.528.5h6.628c2.644,0,4.587-.726,5.774-2.157s1.79-3.774,1.79-6.969-.6-5.53-1.769-6.949S14.625,4.9,11.954,4.9Z"
              transform="translate(0.369 0)" fill="#fff" />
          </g>
        </g>
        <path id="Fill_9" data-name="Fill 9"
          d="M5.386,28H0L9.819,3.546A5.2,5.2,0,0,1,12.035.8,7.5,7.5,0,0,1,15.7,0a7.4,7.4,0,0,1,3.625.8A5.025,5.025,0,0,1,21.5,3.546L30.947,28H25.52L17.028,5.869a1.363,1.363,0,0,0-1.368-.937,1.437,1.437,0,0,0-1.409.937Z"
          transform="translate(100.21)" fill="#fff" />
        <g id="Group_13" data-name="Group 13" transform="translate(136.316)">
          <path id="Clip_12-2" data-name="Clip 12" d="M0,0H21.368V28H0Z" transform="translate(0)" fill="none" />
          <g id="Group_13-2" data-name="Group 13" clip-path="url(#clip-path-3)">
            <path id="Fill_11" data-name="Fill 11"
              d="M4.867,0V18.293a6.51,6.51,0,0,0,.268,1.888,3.511,3.511,0,0,0,.928,1.535,4.588,4.588,0,0,0,1.753,1.017,8.613,8.613,0,0,0,2.744.373H21.368V28h-11.1a13.622,13.622,0,0,1-4.722-.726,8.4,8.4,0,0,1-3.2-2.012A7.651,7.651,0,0,1,.556,22.213,12.816,12.816,0,0,1,0,18.335V0Z"
              transform="translate(0)" fill="#fff" />
          </g>
        </g>
      </g>
    </svg>
  </a>

  <app-language-switcher *ngIf="showLanguageSelector"></app-language-switcher>

  <a [routerLink]="['/', 'yourfutureagency']" class="contact"><span>&gt;</span> your future agency</a>
  <button class="hamburger hamburger--elastic" type="button" aria-label="Menu" aria-controls="navigation"
    (click)="toggleMenu()" [ngClass]="{'active': isMenuActive$ | async}">
    <span></span>
    <span></span>
    <span></span>
    <span></span>
  </button>
  <div class="navigation-menu-circle" [ngClass]="{'active': isMenuActive$ | async}"></div>
</header>
<div class="navigation" *ngIf="isMenuActive$ | async" [@inOutAnimation]>
  <app-navigation-menu></app-navigation-menu>
</div>
