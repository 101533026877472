import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {CaseModel, CaseResponse} from '../models/case.model';
import {BehaviorSubject, Observable, of} from 'rxjs';
import { strapiNormalize } from '../strategy/strapi-response-normalizer';
import {map, switchMap, tap} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CaseService {
  private cases: BehaviorSubject<CaseModel[]> = new BehaviorSubject<CaseModel[]>([]);
  private case$ = this.cases.asObservable();

  constructor(private http: HttpClient) {
  }

  getData(): Observable<CaseModel[]> {
    return this.case$.pipe(
      switchMap((data) => {
        if (data && data.length) { return of(data); }

        return this.fetchAll();
      }),
    );
  }

  private fetchAll(): Observable<CaseModel[]> {
    return this.http.get<CaseResponse>('/banner-projects?populate=*').pipe(
      map((casesResponse) => strapiNormalize(casesResponse.data)),
      tap((data) => this.cases.next(data))
    );
  }
}
