<main class="main-page-container contact-page">
    <app-header class="position-fixed w-10"></app-header>


    <ng-container *ngIf="interview$ | async as interview">
        <app-video-player
            #videoContainerComponent
            [video_360]="interview.video_360"
            [video_540]="interview.video_540"
            [video_720]="interview.video_720"
            [video_1080]="interview.video_1080"
            [captions_file]="interview.captions_file"
            [poster]="interview.poster"
            [showPlayButton]="false"
            (videoEnded)="videoEnded()"
            (timeUpdated)="timeUpdated($event)"
            (init)="videoInit()"
            (videoStarted)="videoStarted()"
        ></app-video-player>
    </ng-container>

    <div *ngIf="subTitle$ | async as subTitle" [@inOutAnimation] class="subtitle-wrapper">
        <h1 class="subtitle">{{subTitle}}</h1>
<!--        <h1 class="subtitle">Where do you think the digital industry will be in 5 years?</h1>-->
    </div>
</main>
