import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {LanguageInterface} from '../../models/languages/language.interface';
import {LanguagesService} from '../../services/languages/languages.service';

@Component({
  selector: 'app-language-switcher',
  templateUrl: './language-switcher.component.html',
  styleUrls: ['./language-switcher.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LanguageSwitcherComponent implements OnInit {

  languages: LanguageInterface[];

  active: string;

  default: string;

  constructor(private languagesService: LanguagesService) { }

  ngOnInit(): void {
    this.languages = this.getLanguages();
    this.default = this.getDefault();
    this.active = this.getActive();
  }

  setActive(lang: string): void {
    if (lang === this.active) return;
    this.active = lang;
    this.languagesService.setActive(lang);
  }

  private getLanguages(): LanguageInterface[] {
    return this.languagesService.getAll();
  }

  private getDefault(): string {
    return this.languagesService.getDefault();
  }

  private getActive(): string {
    return this.languagesService.getSaved();
  }

}
