import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {BlurredVideoComponent} from './blurred-video/blurred-video.component';
import {HeaderModule} from '../../shared/header/header.module';
import {BlurModule} from '../../shared/blur/blur.module';
import {VideoSliderModule} from '../../shared/video-slider/video-slider.module';
import {RouterModule} from '@angular/router';


@NgModule({
  declarations: [BlurredVideoComponent],
  imports: [
    CommonModule,
    HeaderModule,
    BlurModule,
    VideoSliderModule,
    RouterModule
  ]
})
export class LayoutsModule {
}
