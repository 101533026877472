import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './modules/home/home.component';
import { CaseComponent } from './modules/case/case.component';
import { ContactComponent } from './modules/contact/contact.component';
import { CanDeactivateGuard } from './guards/deactivate.guard';
import { YourFutureAgencyComponent } from './modules/your-future-agency/your-future-agency.component';
import { InterviewComponent } from './modules/interview/interview.component';
import { BlurredVideoComponent } from './modules/layouts/blurred-video/blurred-video.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { CookieDeclarationComponent } from './modules/cookie-declaration/cookie-declaration.component';

const routes: Routes = [
    {
      path: 'blog',
      loadChildren: () => import('./modules/blog/blog.module').then((m) => m.BlogModule)
    },
    {
        path: 'cases/:slug',
        component: CaseComponent,
        data: {animation: 'CaseComponent'},
        canDeactivate: [CanDeactivateGuard],
    },
    {
        path: 'interview',
        component: InterviewComponent,
        data: {animation: 'InterviewComponent'},
        canDeactivate: [CanDeactivateGuard],
    },
    {
        path: '',
        component: BlurredVideoComponent,
        data: {animation: 'BlurredVideoComponent'},
        canDeactivate: [CanDeactivateGuard],
        children: [
            {
                path: 'yourfutureagency',
                component: YourFutureAgencyComponent,
                data: {animation: 'YourFutureAgencyComponent'},
            },
            {
                path: 'contact', component: ContactComponent, data: {animation: 'ContactComponent'},
            },
            {
                path: 'cookie-declaration', component: CookieDeclarationComponent, data: {animation: 'ContactComponent'},
            },
            {
                path: '', component: HomeComponent, data: {animation: 'HomeComponent'},
            },
            {
                path: '',
                loadChildren: () => import('./modules/page').then(m => m.PageModule)
            },
        ]
    },
];

@NgModule({
    imports: [BrowserModule, BrowserAnimationsModule, RouterModule.forRoot(routes, {
    initialNavigation: 'enabledBlocking'
})],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
