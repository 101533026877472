<ng-container *ngIf="cases$ | async as cases">
    <ng-container *ngIf="case$ | async as c">
        <main class="main-page-container">
            <app-header class="position-fixed w-10"></app-header>
            <div class="header-banner-wrapper header-banner-wrapper-desktop">
                <ng-container *ngIf="bannerState$ | async as bannerState">
                    <article class="page-body banner-large" (mouseover)="hoveredAction(true)" (mouseleave)="hoveredAction(false)"
                             [class.banner-closed]="bannerState === STATE_CLOSED"
                             [class.banner-small]="bannerState === STATE_BANNER_SMALL"
                             [class.banner-large]="bannerState === STATE_BANNER_LARGE"
                             [class.banner-small-initial]="bannerState === STATE_BANNER_SMALL_INITIAL">
                        <div>
                            <app-blur>
                                <div class="swiper-navs-container">
                                    <div class="swiper-navigation-prev" (click)="swipePrevClick($event, c, cases)"></div>
                                    <h3>swipe for next case</h3>
                                    <div class="swiper-navigation-next" (click)="swipeNextClick($event, c, cases)"></div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <div class="carousel-title" (click)="toggleBanner()"
                                             style="cursor: pointer">
                                            <h1 class="text-center banner-carousel-title">
                                                {{ c.title }}
                                            </h1>
                                            <!-- <div class="scroll-down"></div> -->
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12 col-md-4">
                                        <a
                                                class="slide-item keep-ratio text-center d-flex align-items-center justify-content-center"
                                                [href]="c.file_case.url | apiUrlPipe"
                                                target="_blank"
                                        >
                                            <div class="item-container p-4">
                                                <div class="slider--item-descr">
                                                    <p class="slider-banner-item--descr mb-0">Download case</p>
                                                </div>
                                            </div>
                                        </a>
                                    </div>

                                    <div class="col-12 col-md-4">
                                        <a
                                                class="slide-item keep-ratio text-center d-flex align-items-center justify-content-center"
                                                [href]="c.link"
                                                target="_blank"
                                        >
                                            <div class="item-container p-4">
                                                <div class="slider--item-descr">
                                                    <p class="slider-banner-item--descr mb-0">Visit case</p>
                                                </div>
                                            </div>
                                        </a>
                                    </div>

                                    <div class="col-12 col-md-4">
                                        <div
                                                class="slide-item keep-ratio text-center d-flex align-items-center justify-content-center"
                                                style="cursor: pointer"
                                                [routerLink]="['/contact']"
                                        >
                                            <div class="item-container p-4">
                                                <div class="slider--item-descr">
                                                    <p class="slider-banner-item--descr mb-0">Contact Ovdal</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </app-blur>
                        </div>
                    </article>
                </ng-container>

                <app-video-player
                        (click)="hideBanner()"
                        #videoContainerComponent
                        [video_360]="c.video_360"
                        [video_540]="c.video_540"
                        [video_720]="c.video_720"
                        [video_1080]="c.video_1080"
                        [showPlayButton]="false"
                        (videoStarted)="videoStarted()"
                        (init)="videoInit()"
                        (videoEnded)="videoEnded(c, cases)"
                ></app-video-player>
            </div>
        </main>
    </ng-container>
</ng-container>
