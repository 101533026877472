import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VideoPlayerComponent } from './video-player.component';
import {ApiUrlPipeModule} from '../../pipes/api-url/api-url-pipe.module';


@NgModule({
    declarations: [VideoPlayerComponent],
    imports: [
      CommonModule,
      ApiUrlPipeModule
    ],
    exports: [VideoPlayerComponent]
})
export class VideoPlayerModule {
}
