import { Component, Inject, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import { Subscription } from 'rxjs';
import { BackgroundVideoService } from '../../services/background-video.service';
import {AbstractControl, FormGroup, UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import {switchMap} from 'rxjs/operators';
import { ContactRequestService } from '../../services/contact-request.service';
import { isPlatformBrowser } from '@angular/common';

@Component({
    selector: 'app-contact',
    templateUrl: './contact.component.html',
    styleUrls: ['./contact.component.scss'],
    animations: [
        trigger('contactAnimation', [
            transition(':enter', [
                style({opacity: 0}),
                animate('0.2s cubic-bezier(.02,.52,.36,.95)', style({opacity: 1}))
            ]),
        ]),
    ],
})
export class ContactComponent implements OnInit, OnDestroy {

    form: UntypedFormGroup;
    showThankYouMessage: boolean;

    private subscription = new Subscription();

    constructor(
        private backgroundVideoService: BackgroundVideoService,
        private fb: UntypedFormBuilder,
        private contactRequestService: ContactRequestService,
        private recaptchaV3Service: ReCaptchaV3Service,
        @Inject(PLATFORM_ID) protected platformId,
    ) {
    }

    ngOnInit(): void {
        this.form = this.createFormGroup();

        this.backgroundVideoService.slideToIndex(0);
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    submitForm(): void {
        this.subscription.add(
            this.recaptchaV3Service.execute('sendMessage').pipe(
                switchMap((token) => this.contactRequestService.create({data: {...this.form.value}, token}))
            ).subscribe(() => {
                this.form.reset();
                this.showThankYouMessage = true;
            })
        );
    }

    adjustSize(event): void {
        event.target.style['min-height'] = (2 + event.target.scrollHeight) + 'px';
        if (isPlatformBrowser(this.platformId)) {
            setTimeout(() => {
                document.querySelector('.content-block').scrollTo(0, document.body.scrollHeight);
            }, 2000);
        }
    }

    get email(): AbstractControl {
        return this.form.get('email');
    }

    get message(): AbstractControl {
        return this.form.get('message');
    }

    private createFormGroup(): FormGroup {
      return this.fb.group({
        email: this.fb.control(null, [Validators.email, Validators.required]),
        message: this.fb.control(null, [Validators.minLength(10), Validators.maxLength(1000), Validators.required]),
      });
    }
}
