import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VideoContainerComponent } from './video-container.component';
import {HeaderModule} from '../header/header.module';
import {ApiUrlPipeModule} from '../../pipes/api-url/api-url-pipe.module';

@NgModule({
  declarations: [VideoContainerComponent],
  exports: [
    VideoContainerComponent
  ],
  imports: [
    CommonModule,
    HeaderModule,
    ApiUrlPipeModule,
  ]
})
export class VideoContainerModule { }
