import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header/header.component';
import { NavigationMenuModule } from '../navigation-menu/navigation-menu.module';
import {RouterModule} from '@angular/router';
import {LanguageSwitcherModule} from '../language-switcher/language-switcher.module';



@NgModule({
  declarations: [HeaderComponent],
  imports: [
    CommonModule,
    NavigationMenuModule,
    RouterModule,
    LanguageSwitcherModule
  ],
  exports: [
    HeaderComponent
  ]
})
export class HeaderModule { }
