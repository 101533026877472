import { Component, Inject, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import { Subscription } from 'rxjs';
import { BackgroundVideoService } from '../../services/background-video.service';
import {AbstractControl, FormGroup, UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import {switchMap} from 'rxjs/operators';
import { ContactRequestService } from '../../services/contact-request.service';
import { isPlatformBrowser } from '@angular/common';

@Component({
    selector: 'app-declaration',
    templateUrl: './cookie-declaration.component.html',
    styleUrls: ['./cookie-declaration.component.scss'],
    animations: [
        trigger('declarationAnimation', [
            transition(':enter', [
                style({opacity: 0}),
                animate('0.2s cubic-bezier(.02,.52,.36,.95)', style({opacity: 1}))
            ]),
        ]),
    ],
})
export class CookieDeclarationComponent implements OnInit {

    constructor(
        private backgroundVideoService: BackgroundVideoService) {
    }

    ngOnInit(): void {
        this.backgroundVideoService.slideToIndex(0);
    }
}
