import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input, OnChanges, OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { MediaModel } from '../../models/media.model';
import { timer } from 'rxjs';
import { mapTo } from 'rxjs/operators';

@Component({
    selector: 'app-video-player',
    templateUrl: './video-player.component.html',
    styleUrls: ['./video-player.component.scss']
})
export class VideoPlayerComponent implements OnInit, OnChanges, OnDestroy {
    @ViewChild('videoElement') videoElement: ElementRef;

    @Input() vimeoVideoId: number;

    @Input() muted = true;

    @Input() video_360: MediaModel;
    @Input() video_540: MediaModel;
    @Input() video_720: MediaModel;
    @Input() video_1080: MediaModel;
    @Input() captions_file: MediaModel;
    @Input() poster: MediaModel;

    @Input() loop = false;
    @Input() slogan: string;
    @Input() showPlayButton = true;

    @Output() videoEnded = new EventEmitter();
    @Output() videoStarted = new EventEmitter();
    @Output() timeUpdated = new EventEmitter();
    @Output() init = new EventEmitter();
    hiddenOverlay = false;
    volumeLevel = 0;

    responsive: { [key: number]: MediaModel };
    currentVideo: MediaModel;

    constructor(
        private hostElement: ElementRef,
        private changeDetectorRef: ChangeDetectorRef,
    ) {
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (this.responsive) {
            this.syncResponsiveSources();
            this.syncVideoSource();
        }
        this.changeDetectorRef.detectChanges();
    }

    ngOnInit(): void {
        this.syncResponsiveSources();
        this.syncVideoSource();
    }

    videoLoaded(): void {
        this.init.emit(true);
    }

    ngOnDestroy(): void {
        if (this.videoElement) {
            this.videoElement.nativeElement.pause();
        }
    }

    playVideo(muted = false): void {
        this.showPlayButton = false;
        const media = this.videoElement.nativeElement;
        media.currentTime = 0;
        media.muted = muted;
        media.play();
        this.videoStarted.emit(true);
    }

    ended(): void  {
        this.resetPlayer();
        this.videoEnded.emit(true);
    }

    resetPlayer(): void {
        this.videoElement.nativeElement.load();
    }

    pausePlayer(): void {
        this.videoElement.nativeElement.pause();
    }


    timeUpdate(event): void {
        this.timeUpdated.emit(event.target.currentTime);
    }

    toggleSound(): void {
        this.volumeLevel = this.volumeLevel ? 0 : 1;
    }

    canDeactivate(): Promise<boolean> {
        this.hiddenOverlay = false;
        return timer(1000).pipe(mapTo(true)).toPromise();
    }

    /**
     * Update source based on media query size
     *
     * @private
     *
     * @return void
     */
    private syncVideoSource(): void {

        const properVideoSize = Object.keys(this.responsive)
        .reverse()
        .find(minWidth => this.hostElement.nativeElement.offsetWidth >= parseInt(minWidth, 10));
        this.currentVideo = this.responsive[properVideoSize];
    }


    private syncResponsiveSources(): void {
        this.responsive = {
            0: this.video_360,
            360: this.video_540,
            540: this.video_720,
            720: this.video_1080,
        };
    }

}
