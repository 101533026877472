import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HomeComponent} from './home.component';
import {HeaderModule} from '../../shared/header/header.module';
import {BlurModule} from '../../shared/blur/blur.module';
import {SwiperModule} from 'swiper/angular';
import {VideoBannerItemModule} from '../../shared/video-banner-item/video-banner-item.module';

@NgModule({
  declarations: [HomeComponent],
    imports: [
        CommonModule,
        HeaderModule,
        BlurModule,
        SwiperModule,
        VideoBannerItemModule,
    ]
})
export class HomeModule {
}
