<div class="video-wrapper" *ngIf="currentVideo">
    <video [src]="currentVideo.url | apiUrlPipe" crossorigin="anonymous" playsinline
           [poster]="poster ? (poster.url | apiUrlPipe) : ''"
           #videoElement
           (ended)="ended()"
           (timeupdate)="timeUpdate($event)"
           (loadstart)="videoLoaded()"
           style="width: 100%; height: 100%; object-fit: cover; object-position: center center">
        <track label="English" kind="subtitles" srclang="en" [src]="captions_file.url | apiUrlPipe" default
               *ngIf="captions_file">
    </video>
</div>
