<app-header class="position-fixed" [futureAgencyPath]="['/']" ></app-header>

<section class="contact-page">
  <article class="page-body">
    <app-blur>
      <div class="content-block align-items-top">
        <div class="flex-container">
          <div class="flex-row">
            <div class="d-flex flex-column align-items-center m-auto w-100">
              <div class="contact-page-slogan">
                <h1>Contact</h1>
              </div>
              <ul class="contact-offices">
                <li class="contact-offices--item">
                  <h3>Denmark</h3>
                  <p>Vesterhavnen, 5</p>
                  <p>5800, Nyborg</p>
                  <p>+4578787027</p>
                  <p>info@ovdal.dk</p>
                </li>

                <li class="contact-offices--item">
                  <h3>Ovdal Ukraine</h3>
                  <p>63 Viacheslava Chornovola Avenue</p>
                  <p>79020, Lviv</p>
                  <p>lvivinfo@ovdal.dk</p>
                </li>
              </ul>
              <form (ngSubmit)="submitForm()" [formGroup]="form" class="form">
                <div class="form-row mb-4">
                  <div class="col">
                    <input type="text" class="form-control" placeholder="Your email" id="email"
                      [class.is-invalid]="email.touched && email.invalid" formControlName="email">
                    <div class="invalid-feedback" *ngIf="email.touched && email.invalid">
                      <p *ngIf="email.hasError('required')">Email is required</p>
                      <p *ngIf="email.hasError('email')">Please, provide valid email address</p>
                    </div>
                  </div>
                </div>
                <div class="form-row mb-4">
                  <div class="col">
                    <textarea class="form-control mb-3" id="message" placeholder="Your message" (keyup)="adjustSize($event)" style="overflow:hidden" formControlName="message"
                      [class.is-invalid]="message.touched && message.invalid"></textarea>
                    <div class="invalid-feedback" *ngIf="message.touched && message.invalid">
                      <p *ngIf="message.hasError('required')">Message is required</p>
                    </div>

                    <div>
                      This site is protected by reCAPTCHA and the Google
                      <a href="https://policies.google.com/privacy">Privacy Policy</a> and
                      <a href="https://policies.google.com/terms">Terms of Service</a> apply.
                    </div>
                  </div>
                </div>
                <div class="form-row" *ngIf="showThankYouMessage">
                  <div class="col">
                    <div class="alert alert-primary" role="alert">
                      Thank you! Your request has been sent! We will contact you shortly.
                    </div>
                  </div>
                </div>

                <div class="form-group text-center">
                  <button class="btn btn-primary" type="submit" [disabled]="form.invalid">Send</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </app-blur>
  </article>
</section>
