import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VideoSliderComponent } from './video-slider.component';
import {SwiperModule} from 'swiper/angular';
import {VideoContainerModule} from '../video-container/video-container.module';

@NgModule({
    declarations: [VideoSliderComponent],
    exports: [
        VideoSliderComponent
    ],
  imports: [
    CommonModule,
    SwiperModule,
    VideoContainerModule
  ]
})
export class VideoSliderModule { }
