import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CaseComponent } from './case.component';
import { RouterModule } from '@angular/router';
// import { VideoContainerModule } from '../../shared/video-container/video-container.module';
import { BlurModule } from '../../shared/blur/blur.module';
import { HeaderModule } from '../../shared/header/header.module';
import { VideoPlayerModule } from '../../shared/video-player/video-player.module';
import {ApiUrlPipeModule} from '../../pipes/api-url/api-url-pipe.module';


@NgModule({
    declarations: [CaseComponent],
    imports: [
      CommonModule,
      // SwiperModule,
      RouterModule,
      // VideoContainerModule,
      BlurModule,
      HeaderModule,
      VideoPlayerModule,
      ApiUrlPipeModule
    ]
})
export class CaseModule {
}
