import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { BannerBackgroundVideoModel } from '../../models/banner-background-video.model';
import { shareReplay } from 'rxjs/operators';
import { BackgroundVideoService } from '../../services/background-video.service';
import { SwiperComponent } from 'swiper/angular';
import { animate, style, transition, trigger } from '@angular/animations';

@Component({
    selector: 'app-video-slider',
    templateUrl: './video-slider.component.html',
    styleUrls: ['./video-slider.component.scss'],
    animations: [
        trigger(
                'inOutAnimation',
                [
                    transition(
                            ':enter',
                            [
                                style({opacity: 0}),
                                animate('1s ease',
                                        style({opacity: 1}))
                            ]
                    ),
                    transition(
                            ':leave',
                            [
                                style({opacity: 1}),
                                animate('1s ease',
                                        style({opacity: 0}))
                            ]
                    )
                ]
        )
    ]
})
export class VideoSliderComponent implements OnInit {
    @ViewChild('slider') slider: SwiperComponent;
    @Output() bannerClickedEvent = new EventEmitter();

    videos$: Observable<BannerBackgroundVideoModel[]>;

    subscription = new Subscription();
    offset$ = this.backgroundVideoService.getIndexChange();

    constructor(
            private backgroundVideoService: BackgroundVideoService,
    ) {
    }

    ngOnInit(): void {
        this.videos$ = this.backgroundVideoService.getAll().pipe(shareReplay(1));
    }

    playNextVideo(video: BannerBackgroundVideoModel, videos: BannerBackgroundVideoModel[]): void {
        const isLast = this.backgroundVideoService.getCurrentIndex() >= videos.length - 1;
        const nextIndex = isLast ? 0 : (this.backgroundVideoService.getCurrentIndex() + 1);
        this.backgroundVideoService.slideToIndex(nextIndex);
    }
}
