import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import { CaseModel } from '../../models/case.model';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, combineLatest, Observable, Subject, Subscription } from 'rxjs';
import {debounceTime, filter, map, tap} from 'rxjs/operators';
import { CaseService } from '../../services/case.service';
import { BannerService } from '../../services/banner.service';
import { NgxHowlerService } from 'ngx-howler';
import { VideoPlayerComponent } from '../../shared/video-player/video-player.component';
import { BackgroundAudioService } from '../../services/background-audio.service';

@Component({
    selector: 'app-case',
    templateUrl: './case.component.html',
    styleUrls: ['./case.component.scss'],
})
export class CaseComponent implements OnInit, OnDestroy {

    @ViewChild('videoContainerComponent') videoContainerComponent: VideoPlayerComponent;

    case$: Observable<CaseModel>;
    cases$: Observable<CaseModel[]>;
    bannerState$: Observable<number>;

    cases: CaseModel[];

    STATE_CLOSED = this.bannerService.STATE_CLOSED;
    STATE_BANNER_SMALL = this.bannerService.STATE_BANNER_SMALL;
    STATE_BANNER_LARGE = this.bannerService.STATE_BANNER_LARGE;
    STATE_BANNER_SMALL_INITIAL = this.bannerService.STATE_BANNER_SMALL_INITIAL;

    private videoInitSubject = new Subject();
    private videoInit$ = this.videoInitSubject.asObservable();

    private hovered = new BehaviorSubject(false);
    private hovered$ = this.hovered.asObservable();

    private subscription = new Subscription();

    private readonly fadeToLevel = 0.5;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private caseService: CaseService,
        private bannerService: BannerService,
        private howlerService: NgxHowlerService,
        private backgroundAudioService: BackgroundAudioService
    ) {
    }

    get bannerStateValue(): number {
      return this.bannerService.state;
    }

    ngOnInit(): void {
        this.bannerState$ = this.getBannerState();
        this.initCasesObservable();
        this.initCase();
        this.showSmallBanner();
        this.playVideoListener();

        this.bannerStateSubscription();
    }

    swipePrevClick(event, c: CaseModel, cases: CaseModel[]): void {
        event.preventDefault();
        event.stopPropagation();
        this.swipePrev(c, cases);
    }

    swipeNextClick(event, c: CaseModel, cases: CaseModel[]): void {
        event.preventDefault();
        event.stopPropagation();
        this.swipeNext(c, cases);
    }

    videoInit(): void {
        this.videoInitSubject.next(true);
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    toggleBanner(): void {
        if (this.bannerStateValue === this.STATE_BANNER_LARGE) {
            this.setBannerState(this.STATE_BANNER_SMALL);
        } else {
            this.setBannerState(this.STATE_BANNER_LARGE);
        }
    }

    videoEnded(c: CaseModel, cases: CaseModel[]): void {
        this.videoContainerComponent.resetPlayer();
        this.swipeNext(c, cases);
    }

    videoStarted(): void {
        if (!this.howlerService.get('background')?.playing()) {
            // user just started playing the video, initial bg volume should be 0.2
            this.fadeVolume(this.fadeToLevel);
        } else {
            // User came from homepage, volume now is 1
            this.fadeVolume(1, this.fadeToLevel, 1000);
        }
    }

    hideBanner(): void {
        this.setBannerState(this.STATE_BANNER_SMALL);
    }

    showBanner(): void {
        this.setBannerState(this.STATE_BANNER_LARGE);
    }

    hoveredAction(action: boolean): void {
      this.hovered.next(action);
    }

    private playVideo(muted = false, from = 1, to = this.fadeToLevel, duration = 1000): void {
      this.videoContainerComponent.playVideo(muted);
      this.fadeVolume(from, to, duration);
    }

    private swipePrev(c: CaseModel, cases: CaseModel[]): void {
      const currentIndex = this.getCurrentCaseIndex(cases, c);
      const prev = this.getPrevCase(cases, currentIndex);
      this.redirectToCase(prev);
    }

    private swipeNext(c: CaseModel, cases: CaseModel[]): void {
      const currentIndex = this.getCurrentCaseIndex(cases, c);
      const next = this.getNextCase(cases, currentIndex);
      this.redirectToCase(next);
    }

    private bannerStateSubscription(): void {
        const subs = this.hovered$.pipe(
            debounceTime(2000),
            filter((hovered) => !hovered),
        ).subscribe(() => {
            this.setBannerState(this.STATE_BANNER_SMALL);
        });
        this.subscription.add(subs);
    }

    private playVideoListener(): void {
        this.subscription.add(
            combineLatest([
                this.backgroundAudioService.playing(),
                this.videoInit$,
                this.backgroundAudioService.playing(),
            ]).subscribe(([status, init, playing]) => {
                this.playVideo(!playing, this.fadeToLevel);
            })
        );
    }

    private fadeVolume(from = 1, to = this.fadeToLevel, duration = 1000): void {
        const backgroundSound = this.howlerService.get('background');
        if (backgroundSound) {
            backgroundSound.fade(from, to, duration);
        }
    }

    private redirectToCase(next: CaseModel): void {
        if (next.slug === 'happy-helper') {
            this.router.navigate(['/']).then();
        } else {
            this.router.navigate(['/cases', next.slug]).then();
        }
    }


    private getPrevCase(cases: CaseModel[], currentIndex: number): CaseModel {
        return cases[currentIndex - 1] ? cases[currentIndex - 1] : cases[cases.length - 1] as CaseModel;
    }


    private getNextCase(cases: CaseModel[], currentIndex: number): CaseModel {
        return cases[currentIndex + 1] ? cases[currentIndex + 1] : cases[0] as CaseModel;
    }

    private initCasesObservable(): void {
        this.cases$ = this.caseService.getData().pipe(
          tap((data) => this.cases = data)
        );
    }

    private initCase(): void {
        this.case$ = combineLatest([this.route.params, this.cases$]).pipe(
            map(([params, cases]) => cases.find((c: CaseModel) => c.slug === params.slug)),
        );
    }

    private showSmallBanner(): void {
        if (this.bannerStateValue === this.STATE_CLOSED) {
            this.setBannerState(this.STATE_BANNER_SMALL_INITIAL);
        }
    }

    private getCurrentCaseIndex(cases: CaseModel[], c: CaseModel): number {
        return cases.findIndex(item => item.id === c.id);
    }

    private getBannerState(): Observable<number> {
      return this.bannerService.state$;
    }

    private setBannerState(val: number): void {
      this.bannerService.setState(val);
    }
}
