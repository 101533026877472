import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {BlurModule} from '../../shared/blur/blur.module';
import {HeaderModule} from '../../shared/header/header.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module} from 'ng-recaptcha';
import { CookieDeclarationComponent } from './cookie-declaration.component';


@NgModule({
  declarations: [CookieDeclarationComponent],
  imports: [
    CommonModule,
    BlurModule,
    HeaderModule,
    FormsModule,
    ReactiveFormsModule,
    RecaptchaV3Module,
  ]
})
export class CookieDeclarationModule {
}
