import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {VideoBannerItemComponent} from './video-banner-item.component';
import {RouterModule} from '@angular/router';
import {ApiUrlPipeModule} from '../../pipes/api-url/api-url-pipe.module';


@NgModule({
  declarations: [VideoBannerItemComponent],
  exports: [
    VideoBannerItemComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    ApiUrlPipeModule,
  ]
})
export class VideoBannerItemModule {
}
