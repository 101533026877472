import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {InterviewModel} from '../models/interview.model';
import { map } from 'rxjs/operators';
import { strapiNormalize } from '../strategy/strapi-response-normalizer';

@Injectable({
  providedIn: 'root'
})
export class InterviewService {

  constructor(
    private http: HttpClient,
  ) {
  }

  get(): Observable<InterviewModel> {
    return this.http.get<InterviewModel>('/interview?populate=*')
    .pipe(map(response => strapiNormalize(response) as InterviewModel));
  }
}
