import {Component, OnInit} from '@angular/core';
import {animate, style, transition, trigger} from '@angular/animations';
import {BackgroundVideoService} from '../../services/background-video.service';

@Component({
  selector: 'app-your-future-agency',
  templateUrl: './your-future-agency.component.html',
  styleUrls: ['./your-future-agency.component.scss'],
  animations: [
    trigger('contactAnimation', [
      transition(':enter', [
        style({opacity: 0}),
        animate('0.2s cubic-bezier(.02,.52,.36,.95)', style({opacity: 1}))
      ]),
    ]),
  ],
})
export class YourFutureAgencyComponent implements OnInit {

  constructor(
    private backgroundVideoService: BackgroundVideoService,
  ) {
  }

  ngOnInit(): void {
    this.backgroundVideoService.slideToIndex(0);
  }
}
