import { NavigationService } from '../../services/navigation.service';
import {Component, Inject, OnDestroy, OnInit, PLATFORM_ID} from '@angular/core';
import { CaseService } from '../../services/case.service';
import { BehaviorSubject, Observable, Subscription, timer } from 'rxjs';
import { AnimationBuilder } from '@angular/animations';
// import Swiper core and required modules
import { ProjectTypeService } from '../../services/project-type.service';
import { ProjectTypeModel } from '../../models/project-type.model';
import { BackgroundVideoService } from '../../services/background-video.service';
import { CaseModel } from '../../models/case.model';
import { EffectFade, Navigation } from 'swiper';
import SwiperCore from 'swiper';
import { BannerService } from '../../services/banner.service';
import { AudioService } from '../../services/audio.service';
import { NgxHowlerService } from 'ngx-howler';
import { Router } from '@angular/router';
import {isPlatformBrowser} from '@angular/common';

SwiperCore.use([Navigation, EffectFade]);

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit, OnDestroy {

    STATE_CLOSED = this.bannerService.STATE_CLOSED;
    STATE_BANNER_SMALL = this.bannerService.STATE_BANNER_SMALL;
    STATE_BANNER_LARGE = this.bannerService.STATE_BANNER_LARGE;
    STATE_BANNER_SMALL_INITIAL = this.bannerService.STATE_BANNER_SMALL_INITIAL;

    swiperBreakpoints = {
        0: {
            slidesPerView: 1,
            spaceBetween: 30,
            slidesPerGroup: 1,
        },
    };

    swiperNavigation = {
        prevEl: '.swiper-navigation-prev',
        nextEl: '.swiper-navigation-next',
    };

    cases$: Observable<CaseModel[]>;
    menuState$: Observable<any>;
    showMessage: boolean;
    bannerState$: Observable<number>;
    projectTypes$: Observable<ProjectTypeModel[]>;
    activeSlideIndex$ = new BehaviorSubject(0);

    private subscription = new Subscription();

    constructor(
        private caseService: CaseService,
        private projectTypeService: ProjectTypeService,
        private backgroundVideoService: BackgroundVideoService,
        private builder: AnimationBuilder,
        private bannerService: BannerService,
        private navigationService: NavigationService,
        private audioService: AudioService,
        private howlerService: NgxHowlerService,
        private router: Router,
        @Inject(PLATFORM_ID) private platformId,
    ) {
    }

    ngOnInit(): void {
        this.redirectToFirstCaseAfterTimeout();
        this.bannerState$ = this.getBannerState();
        this.cases$ = this.caseService.getData();
        this.projectTypes$ = this.projectTypeService.getAll();
        this.menuState$ = this.navigationService.isMenuActive();
        this.setBannerState(this.STATE_CLOSED);
        this.setBannerStateByTimer();
        this.syncAudioEnhancedMessage();
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    toggleBanner(): void {
        if (this.bannerService.state === this.STATE_BANNER_LARGE) {
            this.setBannerState(this.STATE_BANNER_SMALL);
        } else {
            this.setBannerState(this.STATE_BANNER_LARGE);
        }
    }

    bannerOverlayClicked(): void {
        this.setBannerState(this.STATE_BANNER_SMALL);
        this.audioService.hideMessage();
    }

    showBanner(): void {
      if (isPlatformBrowser(this.platformId)) {
        if (!/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
          this.bannerService.setState(this.STATE_BANNER_LARGE);
          this.audioService.hideMessage();
        }
      }
    }

    private setBannerState(state: number): void {
      this.bannerService.setState(state);
    }


    private getBannerState(): Observable<number> {
      return this.bannerService.state$;
    }

    private redirectToFirstCaseAfterTimeout(): void {
      if (isPlatformBrowser(this.platformId)) {
        const subs = timer(30000).subscribe(() => {
          this.router.navigate(['/cases', 'happy-helper']).then();
        });
        this.subscription.add(subs);
      }
    }

    private syncAudioEnhancedMessage(): void {
      this.showMessage = !this.howlerService.get('background');
    }

    private setBannerStateByTimer(): void {
      if (isPlatformBrowser(this.platformId)) {
          const timerSub = timer(5000).subscribe(() => {
            this.bannerService.setState(this.STATE_BANNER_SMALL_INITIAL);
          });
          this.subscription.add(timerSub);
      }
  }
}
