<div class="language-switcher">
  <span
    class="lang"
    *ngFor="let language of languages"
    [ngClass]="language.lang === active ? 'active' : ''"
    (click)="setActive(language.lang)"
  >
    {{language.label}}
  </span>
</div>
