import { Component, Inject, OnDestroy, OnInit, PLATFORM_ID, ViewChild } from '@angular/core';
import { InterviewService } from '../../services/interview.service';
import { BehaviorSubject, combineLatest, Observable, Subject, Subscription } from 'rxjs';
import { InterviewModel } from '../../models/interview.model';
import { AudioService } from '../../services/audio.service';
import { NgxHowlerService } from 'ngx-howler';
import { BackgroundAudioService } from '../../services/background-audio.service';
import { VideoPlayerComponent } from '../../shared/video-player/video-player.component';
import { map } from 'rxjs/operators';
import { animate, style, transition, trigger } from '@angular/animations';
import { Router } from '@angular/router';
import { isPlatformBrowser } from '@angular/common';
import {Howl} from 'howler';

@Component({
    selector: 'app-interview',
    templateUrl: './interview.component.html',
    styleUrls: ['./interview.component.scss'],
    animations: [
        trigger(
            'inOutAnimation',
            [
                transition(
                    ':enter',
                    [
                        style({opacity: 0, 'z-index': 999}),
                        animate('1s ease', style({opacity: 1}))
                    ]
                ),
                transition(
                    ':leave',
                    [
                        style({opacity: 1}),
                        animate('1s ease', style({opacity: 0, 'z-index': 999}))
                    ]
                )
            ]
        )
    ]
})
export class InterviewComponent implements OnInit, OnDestroy {
    @ViewChild('videoContainerComponent') videoContainerComponent: VideoPlayerComponent;

    interview$: Observable<InterviewModel>;

    subTitle$: Observable<string>;

    private timeUpdateSubject = new BehaviorSubject(0);
    private timeUpdate$ = this.timeUpdateSubject.asObservable();

    private videoInitSubject = new Subject();
    private videoInit$ = this.videoInitSubject.asObservable();

    private subscription = new Subscription();

    private readonly fadeToLevel = 0.5;

    constructor(
        private interviewService: InterviewService,
        private audioService: AudioService,
        private howlerService: NgxHowlerService,
        private backgroundAudioService: BackgroundAudioService,
        private router: Router,
        @Inject(PLATFORM_ID) protected platformId,
    ) {
    }

    get backgroundHowl(): Howl {
      return this.howlerService.get('background');
    }

    ngOnInit(): void {
        this.interview$ = this.interviewService.get();
        this.subTitle$ = this.getSubTitle();

        this.initialSubscription();
    }

    playVideo(muted = false): void {
        this.videoContainerComponent.playVideo(muted);
        this.fadeVolume();
    }

    timeUpdated(time: any): void {
        this.timeUpdateSubject.next(time);
    }

    videoInit(): void {
        this.videoInitSubject.next(true);
    }

    videoEnded(): void {
        this.fadeVolume(this.fadeToLevel, 1, 1000);
        this.router.navigate(['/']).then();
    }

    videoStarted(): void {
        this.fadeVolume(1, this.fadeToLevel, 1000);
        this.audioService.hideMessage();
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
        this.fadeVolume(this.fadeToLevel, 1, 1000);
    }

    private initialSubscription(): void {
      const initialSub = combineLatest([
        this.backgroundAudioService.playing(),
        this.videoInit$
      ]).subscribe(([status, init]) => {
        this.playVideo(!status);
        this.backgroundAudioListener();
      });

      this.subscription.add(initialSub);
    }

    private backgroundAudioListener(): void {
        if (isPlatformBrowser(this.platformId)) {
            if (this.backgroundHowl) {
                this.backgroundHowl.on('play', () => {
                    this.playVideo(false);
                });
            }
        }
    }

    private fadeVolume(from = 1, to = this.fadeToLevel, duration = 5000): void {
      if (isPlatformBrowser(this.platformId)) {
        if (this.backgroundHowl) {
          this.backgroundHowl.fade(from, to, duration);
        }
      }
    }

    private getSubTitle(): Observable<string> {
      return combineLatest([this.timeUpdate$, this.interview$]).pipe(
        map(([time, interview]) => {
          const result = interview.subtitles.find((subtitle) => subtitle.startTime <= time && time <= subtitle.endTime);

          return result ? result.text : null;
        })
      );
    }
}
