import {NavigationService} from '../../services/navigation.service';

import {Component, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import {ProjectTypeModel} from '../../models/project-type.model';
import {ProjectTypeService} from '../../services/project-type.service';

@Component({
  selector: 'app-navigation-menu',
  templateUrl: './navigation-menu.component.html',
  styleUrls: ['./navigation-menu.component.scss']
})
export class NavigationMenuComponent implements OnInit {
  projectTypes$: Observable<ProjectTypeModel[]>;

  constructor(
    private projectTypeService: ProjectTypeService,
    private navigationService: NavigationService,
  ) {
  }

  ngOnInit(): void {
    this.projectTypes$ = this.projectTypeService.getAll();
  }

  hideMenu(): void {
    this.navigationService.setMenuState(false);
  }
}
