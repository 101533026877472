import { Inject, NgModule, PLATFORM_ID } from '@angular/core';
import {BrowserModule, BrowserTransferStateModule} from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HomeModule } from './modules/home';
import { CaseModule } from './modules/case/case.module';
import { ContactModule } from './modules/contact/contact.module';
import { HeaderModule } from './shared/header/header.module';
import { BlurModule } from './shared/blur/blur.module';
import { VideoSliderModule } from './shared/video-slider/video-slider.module';
import { YourFutureAgencyModule } from './modules/your-future-agency/your-future-agency.module';
import { InterviewModule } from './modules/interview/interview.module';
import { LayoutsModule } from './modules/layouts/layouts.module';
import { NgxHowlerService } from 'ngx-howler';
import { RouteReuseStrategy } from '@angular/router';
import { CustomRouteReuseStrategy } from './strategy/route-reuse.strategy';
import { isPlatformBrowser, HashLocationStrategy, LocationStrategy } from '@angular/common';
import { BrowserStateInterceptor } from './interceptors/browser-state.interceptor';
import {ApiInterceptor} from './interceptors/api.interceptor';
import { CookieDeclarationComponent } from './modules/cookie-declaration/cookie-declaration.component';
import { CookieDeclarationModule } from './modules/cookie-declaration/cookie-declaration.module';

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        BrowserModule.withServerTransition({appId: 'serverApp'}),
        BrowserAnimationsModule,
        AppRoutingModule,
        HttpClientModule,
        NgbModule,

        HeaderModule,
        BlurModule,
        VideoSliderModule,

        HomeModule,
        CaseModule,
        ContactModule,
        CookieDeclarationModule,
        YourFutureAgencyModule,
        InterviewModule,
        LayoutsModule,
        BrowserTransferStateModule,
    ],
    providers: [
      {
        provide: HTTP_INTERCEPTORS,
        useClass: ApiInterceptor,
        multi: true
      },
      NgxHowlerService,
      {
        provide: RouteReuseStrategy, useClass: CustomRouteReuseStrategy,
      },
      {
        provide: HTTP_INTERCEPTORS,
        useClass: BrowserStateInterceptor,
        multi: true
      }
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
    constructor(
        ngxHowlerService: NgxHowlerService,
        @Inject(PLATFORM_ID) protected platformId,
    ) {
        if (isPlatformBrowser(this.platformId)) {
            ngxHowlerService.loadScript('https://cdnjs.cloudflare.com/ajax/libs/howler/2.2.0/howler.min.js');
        }
    }
}
