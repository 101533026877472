<app-header class="position-fixed" [futureAgencyPath]="['/']"></app-header>

<section class="contact-page contact-page-slogan">
    <article class="page-body">
        <app-blur>
            <div class="content-block">
                <div class="d-flex flex-column justify-content-between align-content-center">
                    <h1 class="d-flex flex-grow-1 justify-content-center align-items-center">
                        <span>I've seen the future and it will be</span>
                    </h1>
                    <div class="row">
                        <div class="col-12 col-md-3 mb-3 ">
                            <a [routerLink]="['/about']"
                               class="slide-item keep-ratio text-center d-flex align-items-center justify-content-center">
                                <div class="item-container p-4">
                                    <div class="slider--item-descr">
                                        <p class="slider-banner-item--descr">About</p>
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div class="col-12 col-md-3 mb-3 ">
                            <a [routerLink]="['/interview']"
                               class="slide-item keep-ratio text-center d-flex align-items-center justify-content-center">
                                <div class="item-container p-4">
                                    <div class="slider--item-descr">
                                        <p class="slider-banner-item--descr">Interview <span class="color-gray-light">with the
                          founder</span></p>
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div class="col-12 col-md-3 mb-3 ">
                            <a [routerLink]="['/blog']"
                               class="slide-item keep-ratio text-center d-flex align-items-center justify-content-center">
                                <div class="item-container p-4">
                                    <div class="slider--item-descr">
                                        <p class="slider-banner-item--descr color-gray-light">Blog</p>
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div class="col-12 col-md-3 mb-3 ">
                            <a [routerLink]="['/contact']"
                               class="slide-item keep-ratio text-center d-flex align-items-center justify-content-center">
                                <div class="item-container p-4">
                                    <div class="slider--item-descr">
                                        <p class="slider-banner-item--descr color-gray-light">Contact</p>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </app-blur>
    </article>
</section>
