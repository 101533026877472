<div style="height: 100%;  width: 100%; background: black" [@routeAnimations]="prepareRoute(outlet)">
<!--  <div class="lds-ellipsis">-->
<!--    <div></div>-->
<!--    <div></div>-->
<!--    <div></div>-->
<!--    <div></div>-->
<!--  </div>-->
  <router-outlet #outlet="outlet"></router-outlet>
</div>

<div class="banner-audio-enhanced-wrapper" *ngIf="!(isAudioPlaying | async) && !isBlogPage">
  <div class="banner-audio-enhanced text-center" style="cursor: pointer" (click)="enableAudio()">
    <p>This website is sound enhanced</p>
    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24" fill="white">
      <rect class="eq-bar eq-bar--1" x="4" y="4" width="3.7" height="8"/>
      <rect class="eq-bar eq-bar--2" x="10.2" y="4" width="3.7" height="16"/>
      <rect class="eq-bar eq-bar--3" x="16.3" y="4" width="3.7" height="11"/>
    </svg>
    <p>click to activate</p>
  </div>
</div>
