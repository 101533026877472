import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {ProjectTypeModel, ProjectTypeResponse} from '../models/project-type.model';
import { strapiNormalize } from '../strategy/strapi-response-normalizer';
import {map, switchMap, tap} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ProjectTypeService {
  private cases: BehaviorSubject<ProjectTypeModel[]> = new BehaviorSubject<ProjectTypeModel[]>([]);
  private cases$ = this.cases.asObservable();

  constructor(private http: HttpClient) {
  }

  getAll(): Observable<ProjectTypeModel[]> {
    return this.cases$.pipe(
      switchMap((data) => {
        if (data && data.length) {return of(data); }
        return this.fetchAll();
      })
    );
  }

  private fetchAll(): Observable<ProjectTypeModel[]> {
    return this.http.get<ProjectTypeResponse>('/project-types?populate[banner_projects][populate][preview_gif][populate]=*').pipe(
      map((data) => strapiNormalize(data)),
      tap((data) => this.cases.next(data))
    );
  }
}
