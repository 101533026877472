import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import SwiperCore, { Navigation, } from 'swiper';
import { ActivatedRoute, Router, RouterOutlet } from '@angular/router';
import { BackgroundVideoService } from './services/background-video.service';
import { BackgroundAudioService } from './services/background-audio.service';
import { animate, group, query, style, transition, trigger } from '@angular/animations';
import {Observable, Subscription} from 'rxjs';
import {BannerBackgroundVideoModel} from './models/banner-background-video.model';
import Intercom from '@Intercom/messenger-js-sdk';

SwiperCore.use([Navigation]);

let userid: any;
let username: any;
let useremail: any;
let usercreatedAt: any;
Intercom({
    app_id: 'uaafo93f',
    user_id: userid, // IMPORTANT: Replace "user.id" with the variable you use to capture the user's ID
    name: username, // IMPORTANT: Replace "user.name" with the variable you use to capture the user's name
    email: useremail, // IMPORTANT: Replace "user.email" with the variable you use to capture the user's email
    created_at: usercreatedAt, // IMPORTANT: Replace "user.createdAt" with the variable you use to capture the user's sign-up date in a Unix timestamp (in seconds) e.g. 1704067200
   });

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    animations: [
        trigger(
            'inOutAnimation',
            [
                transition(
                    ':enter',
                    [
                        style({opacity: 0, 'z-index': 999}),
                        animate('1s ease', style({opacity: 1}))
                    ]
                ),
                transition(
                    ':leave',
                    [
                        style({opacity: 1}),
                        animate('1s ease', style({opacity: 0, 'z-index': 999}))
                    ]
                )
            ]
        ),
        trigger('routeAnimations', [
            transition('* <=> *', [
                group([
                    query(':enter', [
                        style({opacity: 0, position: 'absolute', top: 0, width: '100%', height: '100%'}),
                        animate('1s ease', style({opacity: 1}))
                    ], {optional: true}),
                    query(':leave', [
                        style({opacity: 0, position: 'absolute', top: 0, width: '100%', height: '100%'}),
                        animate('2s 1s ease', style({opacity: 1}))
                    ], {optional: true})
                ]),
            ]),
        ])
    ]
})
export class AppComponent implements OnInit, OnDestroy {

    private subscription = new Subscription();

    constructor(
            // private bannerService: BannerService,
            private backgroundVideoService: BackgroundVideoService,
            private route: ActivatedRoute,
            // private audioService: AudioService,
            private router: Router,
            private backgroundAudioService: BackgroundAudioService,
            // private howl: NgxHowlerService,
            // @Inject(PLATFORM_ID) private platformId: any
    ) {
        // AppComponent.isBrowser.next(isPlatformBrowser(platformId));
    }
    // @ViewChild('audioElement') audioElement: ElementRef;
    // static isBrowser = new BehaviorSubject<boolean>(null);
    // startPlaying$ = new Subject();
    // showMessage = true;

    @HostListener('document:click', ['$event']) clickout(event): void {
        if (this.router.url.indexOf('blog') === -1) {
            this.enableAudio();
        }
    }

    get isAudioPlaying(): Observable<boolean> {
      return this.backgroundAudioService.playing();
    }

    get isBlogPage(): boolean {
      return this.router.url.indexOf('blog') !== -1;
    }

    ngOnInit(): void {
        alert("Updates applied");
        this.getAllVideosSub();
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    enableAudio(): void {
      // if (isPlatformBrowser(this.platformId)) {
        const audioSub = this.backgroundAudioService.init().subscribe();
        this.subscription.add(audioSub);
      // }
      //   this.backgroundAudioService.init();
    }
    prepareRoute(outlet: RouterOutlet): string {
      return (outlet && outlet.isActivated && outlet.activatedRoute) ? outlet.activatedRoute.snapshot.params.slug : 0;
    }

    private getAllVideos(): Observable<BannerBackgroundVideoModel[]> {
      return this.backgroundVideoService.getAll();
    }
    private getAllVideosSub(): void {
      const videosSub = this.getAllVideos().subscribe();

      this.subscription.add(videosSub);
    }
}
