import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class AudioService {

    private readonly _play = new BehaviorSubject(false);
    private readonly _message = new BehaviorSubject(true);
    private readonly _volume = new BehaviorSubject(1);

    showMessage$: Observable<boolean> = this._message.asObservable();

    constructor() {
    }

    playAudio(): void {
        this._play.next(true);
    }

    isEnabled(): boolean {
        return this._play.value;
    }

    stopAudio(): void {
        this._play.next(false);
    }

    showMessage(): void {
        this._message.next(true);
    }

    hideMessage(): void {
        this._message.next(false);
    }

    setVolume(volume: number): void {
        this._volume.next(volume);
    }

    getVolumeSnapshot(): number {
        return this._volume.value;
    }
}
