import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ContactComponent} from './contact.component';
import {BlurModule} from '../../shared/blur/blur.module';
import {HeaderModule} from '../../shared/header/header.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module} from 'ng-recaptcha';


@NgModule({
  declarations: [ContactComponent],
  imports: [
    CommonModule,
    BlurModule,
    HeaderModule,
    FormsModule,
    ReactiveFormsModule,
    RecaptchaV3Module,
  ],
  providers: [{ provide: RECAPTCHA_V3_SITE_KEY, useValue: '6LdqpJ4aAAAAAKyFYGY-yqPgRsbxg9zBIFSRm5RB' }],
})
export class ContactModule {
}
