<app-header class="position-fixed" [futureAgencyPath]="['/']" ></app-header>

<section class="contact-page">
  <article class="page-body">
    <app-blur>
      <div class="content-block align-items-top">
        <div class="flex-container">
          <div class="flex-row">
            <div class="d-flex flex-column align-items-center m-auto w-100">
              <div class="contact-page-slogan">
                <h1>Cookie Declaration</h1>
              </div>
              <script id="CookieDeclaration" 
                      src="https://consent.cookiebot.com/081ff87a-c930-4d0f-9003-231ab04d3b67/cd.js" 
                      type="text/javascript" 
                      async>
              </script>
            </div>
          </div>
        </div>
      </div>
    </app-blur>
  </article>
</section>
