<div class="video-wrapper" *ngIf="currentVideo">
    <video muted="muted" [src]="currentVideo.url | apiUrlPipe" crossorigin="anonymous" playsinline
           [poster]="poster ? (poster.url | apiUrlPipe) : ''"
           #videoElement
           (ended)="ended()"
           style="width: 100%; height: 100%; object-fit: cover; object-position: center center">
        <track label="English" kind="subtitles" srclang="en" [src]="captions_file.url | apiUrlPipe" default
               *ngIf="captions_file">
    </video>

    <div style="width: 100%; height: 100%;position:absolute; left: 0; top: 0; background-color: rgba(0,0,0,0.2)" *ngIf="showPlayButton">
        <button class="position-absolute button-play border-0 bg-transparent"
                (click)="playMutedVideo()">
            <i class="far fa-play-circle"></i>
        </button>
    </div>

</div>
