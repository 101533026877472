import { NavigationService } from '../../../services/navigation.service';
import { Component, Input, OnInit } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AudioService } from '../../../services/audio.service';
import { NgxHowlerService } from 'ngx-howler';
import { BackgroundVideoService } from '../../../services/background-video.service';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
    animations: [
        trigger(
            'inOutAnimation',
            [
                transition(
                    ':enter',
                    [
                        style({opacity: 0}),
                        animate('.3s .4s ease-out',
                            style({opacity: 1}))
                    ]
                ),
                transition(
                    ':leave',
                    [
                        style({opacity: 1}),
                        animate('.3s ease-in',
                            style({opacity: 0}))
                    ]
                )
            ]
        )
    ]
})
export class HeaderComponent implements OnInit {

    @Input() futureAgencyPath = ['/'];

    isMenuActive$: Observable<boolean>;

    constructor(
        private navigationService: NavigationService,
        private router: Router,
        private audioService: AudioService,
        private howl: NgxHowlerService,
        private backgroundVideoService: BackgroundVideoService,
    ) {
    }

    get showLanguageSelector(): boolean {
      return this.router.url.indexOf('blog') !== -1;
    }

    ngOnInit(): void {
        this.isMenuActive$ = this.navigationService.isMenuActive();
    }

    showMenu(): void {
        this.navigationService.showMenu();
    }

    toggleMenu(): void {
        this.navigationService.toggleMenu();
        this.backgroundVideoService.slideToIndex(0);
    }

    logoClick(): void {
        this.navigationService.hideMenu();
        if (this.howl.get('background') && !this.howl.get('background').playing()) {
            this.audioService.showMessage();
        }
    }

    goToDynamicPage(event): void {
      event.preventDefault();
      this.router.navigate(this.futureAgencyPath).then();
    }
}
