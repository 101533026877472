import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import {LanguageInterface} from '../../models/languages/language.interface';
import {LocalstorageService} from '../localStorage/local-storage.service';

@Injectable({
  providedIn: 'root'
})
export class LanguagesService {

  private default = 'en';

  activeLanguage$: BehaviorSubject<string> = new BehaviorSubject<string>(this.default);

  private readonly languages: LanguageInterface[] = [
    {
      lang: 'en',
      label: 'EN',
      active: false
    },
    {
      lang: 'da-dk',
      label: 'DK',
      active: false
    }
  ];


  constructor(
    private localstorageService: LocalstorageService,
  ) {
    this.activeLanguage$.next(this.getSaved());
  }

  getSaved(): string {
    const lang = this.localstorageService.getItem('lang');
    return !lang ? this.default : lang;
   }

  getAll(): LanguageInterface[] {
    return this.languages;
  }

  getDefault(): string {
    return this.default;
  }

  setActive(lang: string): void {
    this.localstorageService.setItem('lang', lang);
    this.activeLanguage$.next(lang);
  }
}
